import { takeLatest, put, call, select } from "redux-saga/effects";
import { REQUEST_GET, SAVE_DATA } from "./actionTypes";
import { RequestGet } from "src/assets/js/com";

// generator函数 与 yield 搭配使用
function* rootSaga() {
	// 等待捕获action
	yield takeLatest(REQUEST_GET, RequestGetFun);
}

function* RequestGetFun({ ...data }: any) {
	const payload = data.payload;
	
  	// message默认为 => '存store、直接请求数据' 模式
	const message: Record<string, boolean> = {
		isStore: true,
		isRequest: true
	};

	if (payload.message) {
		Object.keys(payload.message).map((key: string) => {
			message[key] = payload.message[key];
			return null
		});
	}
 
	const state = yield select();
	let response = yield call(RequestGet, state, {...payload, message});
	if (response.retCode !== "0") {
		response = null;
	}

  	// 判断是否需要存储到store
	if (message.isStore) {
		const res: Record<string, any> = {};
		res[`${payload.type[0]}_${payload.type[1]}`] = response;
		yield put({
			type: SAVE_DATA,
			payload: res
		});
	}

	if (data.payload.callback) {
		data.payload.callback(response);
	}
}

export default rootSaga;
