import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import reducer from "./reducer";
import changeReducer from "./changeReducer/index";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

// 合并多个reducers
const multiReducers = combineReducers({
   reducer,
   changeReducer
})

// import thunk from 'redux-thunk'
/*
// thunk 模式
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
   (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(multiReducers, enhancer); // 创建数据存储仓库
*/

// saga 模式
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
const store = createStore(multiReducers, enhancer);

sagaMiddleware.run(rootSaga); // 执行run方法，让saga运行起来。

// 不使用中间件模式
// const store = createStore(
//    multiReducers,
//    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
//    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
// );
export default store;
