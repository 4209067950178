import { isChange } from './changeActionType'
const initState = {
    openid:"",
    nickname:"",
    sex:"",
    headimgurl:"",
    isLogin:0,
    appId:"",
    timeStamp:"",
    nonceStr:"",
    signature:"",
    authority:0
  }
const changeReducer = (state=initState, action: any) => {
    switch (action.type) {
        case isChange:
          return {
              ...state, 
              value: action.payload
        }
        default:
            return {
                ...state
            }
    }
}
export default changeReducer