
import { SAVE_DATA } from './actionTypes'

export default (state: any={}, action: any) => {
  // console.log('reducer1: ', state, action)

  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case SAVE_DATA:
      // console.log('reducer2:', state, action)
      newState = { ...newState, ...action.payload };
      return newState;

    default:
      return state
  }
};
