import * as React from "react";
import { Menu,Popover } from "antd";
import { logout } from 'src/assets/js/misc'
import "../../css/Head.scss";
import * as common from 'src/assets/js/common'
import Timeing from './components/Timeing'

interface IState {
	dateTime: any;
	menus: any;
	openKeys: string[];
	rootSubmenuKeys: string[];
}

const { SubMenu } = Menu;
let timer: any = null;
const title = localStorage.getItem('title')
const logoImg = localStorage.getItem('logoImg')
const org = localStorage.getItem(common.OrgTag);

class Head extends React.Component<any, IState> {
	public constructor(props: any) {
		super(props);

		this.state = {
			dateTime: {
				fullyear: "",
				month: "",
				nowday: "",
				hours: "",
				minutes: "",
				seconds: "",
			},
			menus: [], // 菜单配置的路由
			openKeys: [], // 选中的菜单项
			rootSubmenuKeys: [], // 所有二级菜单选项
		};

		this.menuTool.bind(this);
		this.replaceRouter.bind(this);
		// console.log(props)
	}

	public componentDidMount() {
		this.getMenus();
	}

	public componentWillUnmount(){
		clearTimeout(timer);
	}

	// 获取菜单事件
	public getMenus = () => {
		let menus: any = window.localStorage.getItem('menus')
		menus = menus ? JSON.parse(menus) : []

		let rootSubmenuKeys = window.localStorage.getItem('rootSubmenuKeys')
		rootSubmenuKeys = rootSubmenuKeys ? JSON.parse(rootSubmenuKeys) : []

		this.setState({ menus, rootSubmenuKeys: rootSubmenuKeys as any })


	}

	// 菜单点击二级菜单事件
	public onOpenChange = (keys: string[]) => {

		const {openKeys, rootSubmenuKeys} = this.state
		
		const latestOpenKey:any = keys.find(key => openKeys.indexOf(key) === -1);

		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			this.setState({ openKeys: keys });
		} else {
			this.setState({
				openKeys: latestOpenKey ? [latestOpenKey] : [],
			});
		}
	}

	public replaceRouter(path: string) {
		const { history: { push } } = this.props;
		push(path)
	}

	public menuTool = () => {
		const { menus,openKeys } = this.state
		if (!menus || menus.length === 0) { return; }

		return (
			<Menu className="menu" mode="inline" onOpenChange={this.onOpenChange} openKeys={openKeys}>
				{menus.map((item: any) => {
					if (item.childResources && item.childResources.length > 0) {
						return (
							<SubMenu title={item.resourcesName} key={item.resourcesId}>
								{item.childResources.map((subItem: any) =>(
									<Menu.Item key={subItem.resourcesId} onClick={() => this.replaceRouter(subItem.resourcesUri)}>{subItem.resourcesName}</Menu.Item>
								))}
							</SubMenu>
						)
					} else {
						return (
							<Menu.Item key={item.resourcesId} onClick={() => this.replaceRouter(item.resourcesUri)}>{item.resourcesName}</Menu.Item>
						)
					}
				})}
			</Menu>
		)
	}
		
	

	public render() {
		return (
			<div className="Head SourceHanSansCN">
				<img alt="" className={ this.props.type === 'func' ? "logo funBoard":"logo" } src={ logoImg || require('src/assets/images/logo_poi.png') } />
				{/* <img src={ this.state.logoImg || require('src/assets/images/LOGO.png') } /> */}
				<span className="centx">{ this.props.name || title || '海螺水泥广东区域数字运营系统1' }</span>
				<div className="toolBox" >
				<Popover placement="bottomLeft" content={this.menuTool()} trigger="click"
					overlayClassName={ this.props.type === 'func' ? 'fun-popover' : 'board-popover'}>
					<img alt=""
						src={require("src/assets/images/menu.png")}
						className="img"
					/>
				</Popover>
				</div>
				<div className="rightLayout" >
					<Timeing />
					{/* <img
						src={require("src/assets/images/analy.png")}
						className="img"
					/> */}
					{/* 分割线（功能组件页面） */}
					{ this.props.type === 'func' ? <span className="boundary" /> : null }
					<div className="dispF logout"  onClick={() => logout(org as string) }>
						<img alt=""
							src={require("src/assets/images/logout.png")}
							className="img"
						/>
						<span>退出</span>
					</div>

				</div>

			</div>
		);
	}
}

export default Head
