
import * as storage from './localStorage'
import * as common from './common'

// 获得当前页面的地址 (URL)，并根据参数路径新建窗口
function openWindow(path:string) {
  const url = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + path;
  window.open(url);
  return;
}

// 延迟重新加载
function delayReload(time = 500) {
  setTimeout(function () {
    window.location.reload();
  }, time);
  return;
}

// 退出登录
// function logout(_this){
// 	// 恢复state.disShareData为默认值
// 	_this.$store.commit("RecoverDisShareData",{});

// 	// 清除LocalStorage
// 	for(let tag of common.DelLocalStorageTag) {
// 	  storage.del(tag);
// 	}

//     let project = storage.get('project');
//     let path = project !== null ? project : '';
//     _this.$router.push('/'+path);
// }

function logout(org?: string) {
  // console.log('退出登录')
  clearStorageExcept();
  if(org){
    storage.set(common.OrgTag, org)
  }
  if(window.location.pathname !== '/Login'){
    window.location.pathname = '/Login';
  }
 
}

// 清除除project外的storage
function clearStorageExcept() {
  // 清除LocalStorage
  for (const tag of common.DelLocalStorageTag) {
    storage.del(tag);
  }
}

// 更新企业模型
function delRedMsg(data:any) {
  const tag = ['retCode', 'retMsg']
  for (const val of tag) {
    delete data[val]
  }
  return data
}

// 检查对象是否为空
function checkNotEmpty(obj:any) {
	let res = false;
	if((obj !=='')&&(obj !==undefined)&&(obj !==null)){
		res = true;
  }
	return res;
}

// 检查数组中是否有为空
function checkArrNotEmpty(arr:any) {
  for(const val of arr){
    if(!checkNotEmpty(val)){
      return false;
    }
  }
	return true;
}

// 根据地址打开应用
function openApp(path:string) {
  // window.open(path, '_blank')
  window.open(path, '_self')
}

/**
 * 拼接对象为请求字符串
 * @param {Object} obj - 待拼接的对象
 * @returns {string} - 拼接成的请求字符串
 */
function encodeSearchParams(obj:any) {
  const params:any = []

  Object.keys(obj).forEach((key) => {
    let value = obj[key]
    // 如果值为undefined我们将其置空
    if (typeof value === 'undefined') {
      value = ''
    }
    // 对于需要编码的文本（比如说中文）我们要进行编码
    params.push([key, encodeURIComponent(value)].join('='))
  })

  return '?' + params.join('&')
}


// 跳转至poi-union登录服务
function switchToPoiUnion(org:string,clear:boolean=false) {
  if(clear){ clearStorageExcept() }
  // base加密提交
  // const params = {
  //   client: base64Encode(common.ClientType),
  //   org: base64Encode(org),
  //   app: base64Encode(location.protocol + '//' + location.host)
  // };
  // const url = common.PoiUnionURL + encodeSearchParams(params)

  // 本地环境提交
  const url = 'http://test.poi-t.cn:8200/?client=0311Y2xv4ZIidWQ%3D&org=a151cG9pLoJiSXRlY2g%3DpM&app=9731aHR0pVhjeNDXPcDovL2xvY2FsaG9zs6EDpdDo4MDk5';
  // const url = 'http://test.poi-t.cn:8200/?client=4511Z3JvnVusd9HX76tXA%3D&org=b541cG9pLKVWhhaWx1bw%3Dt%3D&app=9731aHR0pVhjeNDXPcDovL2xvY2FsaG9zs6EDpdDo4MDk5';
  // 测试环境提交
  // const url = 'http://test.poi-t.cn:8200/?client=dc01Z3JvcvTdXA%3DC&org=9111cG9pXk9LWhhaAoRWsIoWx1bw%3D%3D&app=20f1aHR0cDoUcpj%2BZYJmvL3Rlc3QucG9pLXQuY246ODIwMY3mG46OEw%3D%3D'
  openApp(url)
}

// function base64Encode(str:any) {
//   // base64加密
//   return Base64.encode(str);
// }


export {
  openWindow,
  delayReload,
  logout,
  clearStorageExcept,
  openApp,
  checkNotEmpty,
  checkArrNotEmpty,
  encodeSearchParams,
  delRedMsg,
  switchToPoiUnion
}

