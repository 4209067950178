import * as React from 'react';
import { Route } from "react-router-dom";
import RouterGuard from './RouterGuard';
import store from '../store';  // 为路由组件添加 props => dispatch
import * as ACTION from '../store/actionTypes'  // 全局处理 dispatch 状态
import { API } from 'src/assets/js/apiConfig';
import Head from 'src/components/lib/menu/Head';

const classConfig: Record<string, string> = {
    'board': 'MainContainer',
    'func': 'MainFunContainer'
}

const RenderRoutesMap = (routes: any) => ( routes.map(
    ( route: any, index: number ) => {
        return (
            <Route exact={ route.exact||false } key={index} path={route.path} render={props => {
                return (
                    <div className={ classConfig[route.type] || "" }>
                        <div className='polestarboard' >
                            { classConfig[route.type] ?  <Head {...route} {...props} /> : ''}
                            <RouterGuard ACTION = { ACTION } API= { API } dispatch = {store.dispatch} {...route} {...props} />
                        </div>
                    </div>
                )}
            }/>
        )
    })
)

export default RenderRoutesMap;
