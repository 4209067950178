interface IRouterConfigModel {
  name?: string;
  path: string;
  component?: string;
  routes?: any;
  exact?: boolean;
}
let RouterConfig: IRouterConfigModel[] = [
  {
    path: "/",
    component: "components/common/LoginError",
    exact: true
  },
  {
    path: "/Login",
    component: "components/common/Login",
    exact: true
  }
];

/* 看板路由配置 start */
let BoardRouter: IRouterConfigModel[] = [
  {
    path: "/main",
    component: "components/lib/menu/MainBoard",
    exact: true
  },
  {
    name: "区域产销进度",
    path: "/pro_sell",
    component: "components/lib/menu/AreaProMarketSD"
  },
  {
    name: "基地产销进度对比",
    path: "/BaseProMarketSDComp",
    component: "components/lib/menu/BaseProMarketSDComp"
  },
  {
    name: " 基地产销进度情况",
    path: "/BaseProMarketSDCond",
    component: "components/lib/menu/BaseProMarketSDCond"
  },
  {
    name: "环保数据指标",
    path: "/env_protect_index",
    component: "components/lib/menu/EnvDataIndex"
  },
  {
    name: "生产管控指标",
    path: "/pro_index",
    component: "components/lib/menu/ProdContIndex"
  },
  {
    name: "主机设备管控指标",
    path: "/main_device_index",
    component: "components/lib/menu/MainEquipContIndex"
  },
  {
    path: "/PermissionMissing",
    component: 'components/lib/menu/PermissionMissing'
  }
];

// 加上看板主体框架 Main
BoardRouter = JSON.parse(JSON.stringify(BoardRouter).replace(/("component")/g, `"type":"board",$1`));

/* 看板路由配置 end */

/* 功能路由配置 start */
let FunctionalRouter: IRouterConfigModel[] = [
  {

    name: "生产监测",
    path: "/pro_monitor",
    component: "components/lib/menu/ProductionMonitor"
  },
  {
    name: "用户权限配置",
    path: "/user_permission_manage",
    component: "components/lib/menu/UserRightsConfig"
  },
  {
    name: "峰谷平用电量",
    path: "/fgp_use",
    component: "components/lib/menu/PeakValEle"
  },
  {
    name: "峰谷平利用率",
    path: "/fgp_rate",
    component: "components/lib/menu/PeakValUtilRate"
  },
  {
    name: "能源消耗",
    path: "/energy_use",
    component: "components/lib/menu/EnergyConsumption"
  },
  {
    name: "标准管理",
    path: "/standard_manage",
    component: "components/lib/menu/StandardManagement"
  }
];

// 加上功能主体框架 Main
FunctionalRouter = JSON.parse(JSON.stringify(FunctionalRouter)
  .replace(/("component")/g,`"type":"func",$1`));

/* 功能路由配置 end */

RouterConfig = [
  ...RouterConfig,  // 基础路由
  ...BoardRouter,  // 看板路由
  ...FunctionalRouter,  // 功能路由
  {  // 其他路由
    path: "/*",
    component: "components/common/LoginError",
    exact: true
  }
];

export default RouterConfig;
