import * as http from "./Http";
import { API } from "./apiConfig";
import { RsaPublicKey } from './common';
import JSEncrypt from 'jsencrypt'

// 设置日期/月份/年份加减、设置返回格式
const dateMessage = (date: Date, days: number = 1, format: string = "ymdhms", messageType: string = 'day', type: string = '-'): string => {

  if (days === undefined || days == null) {
    days = 1;
  }

  const newDate = dateInterval(date, messageType, days);

  const resDate = dateTimeFormat(newDate, format, type)

  return resDate;
}

const getInitDate = (arg: number): string => {
  if (!arg && arg !== 0) {
    return "";
  }
  let data: string = arg.toString();
  if (data.length < 2) {
    data = `0${data}`;
  }

  return data;
};

// 将指定年/月/日 加减
const dateInterval = (date: Date, messageType: string, days: number) => {
  const newDate = new Date(date);
  if (messageType === "day") {
    newDate.setDate(date.getDate() + days);
  } else if (messageType === "month") {
    newDate.setMonth(date.getMonth() + days);
  } else if (messageType === "year") {
    newDate.setFullYear(date.getFullYear() + days);
  } else if (messageType === "hour") {
    newDate.setHours(date.getHours() + days);
  } else if (messageType === "minute") {
    newDate.setMinutes(date.getMinutes() + days);
  } else if (messageType === "second") {
    newDate.setSeconds(date.getSeconds() + days);
  }

  return newDate;
};


const dateTimeFormat = (date: Date, format: string, type = "-"): string => {

  const y = date.getFullYear();
  const M = getInitDate(date.getMonth() + 1);
  const d = getInitDate(date.getDate());
  const h = getInitDate(date.getHours());
  const m = getInitDate(date.getMinutes());
  const s = getInitDate(date.getSeconds());

  let resDate = '';

  if (format === 'ymdhms') { resDate = `${y}${type}${M}${type}${d} ${h}:${m}:${s}` }
  else if (format === 'ymdhm') { resDate = `${y}${type}${M}${type}${d} ${h}:${m}` }
  else if (format === 'ymdh') { resDate = `${y}${type}${M}${type}${d} ${h}` }
  else if (format === 'ymd') { resDate = `${y}${type}${M}${type}${d}` }
  else if (format === 'ym') { resDate = `${y}${type}${M}` }
  else if (format === 'y') { resDate = `${y}` }

  return resDate
}

const rsaEncrypt = (content: string) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(RsaPublicKey);
  const encrypted = encrypt.encrypt(String(content));
  return encrypted;
}

const deepCopy = (obj1: any) => {
  const type = Object.prototype.toString.call(obj1)
  let resObj: any = null;
  // 引用类型
  if (type.includes('object')) {
    if (type === '[object Array]') {
      resObj = []
      for (let i = 0; i < obj1.length; i++) {
        if (typeof obj1[i] === 'object') {
          resObj[i] = deepCopy(obj1[i])
        } else {
          resObj[i] = obj1[i]
        }
      }
    }
    if (type === '[object Object]') {
      resObj = {}
      for (const key in obj1) {
        if (typeof obj1[key] === 'object') {
          resObj[key] = deepCopy(obj1[key])
        } else {
          resObj[key] = obj1[key]
        }
      }
    }
  } else {
    // 基本类型
    resObj = obj1
  }
  return resObj
}

// 千分符处理
const thousandsNumFun = (num: number, limit: number = 0) => {
  if (typeof num !== 'number') {
    return num;
  }
  return num.toLocaleString('us', { maximumFractionDigits: limit });
}

const toFixedFun = ({ data, num = 2, failShow = null }: Record<string, any>) => {
  return (data !== null && data !== undefined && data !== {}) ? (Math.round(Number(data) * Math.pow(10, num)) / Math.pow(10, num)) : failShow;
}

/*
  功能-节流防抖
	immediate : 是否立即执行
	delay: 延时时间
	callback: 回调函数
*/
export class Debounce {
	private isDelay: boolean = false;
	private timer: any = null;

	// 防抖
	public debounce(callback: any, delay: number = 200) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			return callback();
		}, delay);
	}
	// 节流
	public throttle(callback: any, delay: number = 200) {
		if (!this.isDelay) {
			setTimeout(() => {
			this.isDelay = false;
			}, delay);
			this.isDelay = true;
			return callback();
		}
	}
}

/********* ajax请求 start *********/

interface IRequestGetMsg {
  type: string[]; // 接口名称
  params: Record<string, any>; // 接口参数
  message: {
    isStore?: boolean; // 是否需要存缓存
    isRequest?: boolean; // 是否直接请求数据
  };
  Dom?: string; // loading 的 Dom节点
}

// 请求GET数据
const RequestGet = async (state: any, msgObj: IRequestGetMsg) => {
  let stateData: any = null;
  try {
    stateData = state[msgObj.type[0]][msgObj.type[1]];
  } catch (error) {
    console.log(`state ${msgObj.type[0]}-${msgObj.type[1]} 没有缓存`)
  }
  // console.log('com-RequestGet', msgObj, stateData)
  // 若为直接请求模式 或 非直接请求模式且store没有缓存数据，则重新请求接口
  if (
    msgObj.message.isRequest !== false ||
    (msgObj.message.isRequest === false && !stateData)
  ) {
    return http.get(
      {
        params: msgObj.params,
        url: API[msgObj.type[0]][msgObj.type[1]]
      },
      async (res: any) => {
        return res;
      }
    );
  } else {
    // 通过 store 缓存直接获取数据
    return stateData;
  }
};
/********* ajax请求 end *********/

export { dateMessage, dateTimeFormat, dateInterval, getInitDate, deepCopy, rsaEncrypt, thousandsNumFun, toFixedFun, RequestGet };
