import * as React from 'react'
import * as ReactDOM from 'react-dom'
import AppRouter from './router/AppRouter'
import './main.scss'
// redux
// import { createStore , applyMiddleware , compose } from "redux"
// import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import store from './store'

// antd 组件中文配置
import zh_CN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

ReactDOM.render(
  <Provider store={ store }>
    <ConfigProvider locale={ zh_CN }>
      <AppRouter />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement
);
/*
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore , applyMiddleware , compose } from "redux"
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  Route ,
  Link ,
  Redirect,
  Switch
} from 'react-router-dom'
import {counter , removegunAsyn} from './redux/redux.js'
import reducers from './reducers.js'

import './config/rem.js'
import './config/config'
import './index.scss';
import App from './App';

//调试工具的安装
var reduxDevtools = window.devToolsExtension?window.devToolsExtension():f=>f
//applyMiddleware(thunk)  处理异步 请求  //compose(applyMiddleware(thunk),reduxDevtools)结合调试工具
var store =  createStore(reducers,compose(applyMiddleware(thunk),reduxDevtools))

// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  (<Provider  store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>),
   document.getElementById('root')
);

*/