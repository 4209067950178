
import { EFF_URL, EQU_URL, ENT_URL, PRO_URL, RPT_URL, STA_URL, OAUTH, EMS_URL, API_URL, PR_URL } from './urlConfig';
console.log(EFF_URL, EQU_URL, ENT_URL, PRO_URL, RPT_URL, STA_URL, OAUTH, EMS_URL, API_URL);

export const API: Record<string, any> = {
	api: {
		// 登录相关API
		password: API_URL + 'login/password',  // 密码登录接口
		sendVerCode: API_URL + 'login/sendVerCode',  // 获取手机登录验证码
		verificationCode: API_URL + 'login/verificationCode',  // 手机验证码登录接口

		// 区域平台相关配置相关接口
        get:  API_URL + 'region/code/get',  // 获取区域code
        
        // 区域平台主机设备管控指标相关接口
        getCementMillList: API_URL + 'equipment/stat/cementMill/list', // 获取水泥磨统计信息列表
        getOriginMillList: API_URL + 'equipment/stat/originMill/list', // 获取生料磨统计信息列表
        getRotaryKilnList: API_URL + 'equipment/stat/rotaryKiln/list', // 获取回转窑统计信息列表
        getEquipmentIndex: API_URL + 'equipment/stat/equipment/index/get', // 获取各类设备统计指标
        getCode:  API_URL + 'region/code/get',  // 获取区域code
        
        // 区域平台看板相关接口
        getHomePageInfo:  API_URL + 'board/homePageInfo/get',  // 获取区域 首页信息
        
        // 区域监测接口
		listAll: API_URL + 'region/monitor/all/list',  // 获取区域下所有生产监测配置项
		listVisible: API_URL + 'region/monitor/visible/list',  // 获取可见生产监测列表
        saveConfig: API_URL + 'region/monitor/config/save',  // 保存区域下生产监测配置
        
        fgpUserRatioGet: `${API_URL}board/fgpUserRatio/get`, // 峰谷平利用率
        energyTypeList: `${API_URL}energy/usage/type/list`, // 获取区域下所有能源类型
        energyUsagelist: `${API_URL}energy/usage/enterprise/usage/list`, // 获取基地下某年、某月能源小号分布
        energyTypeUsage: `${API_URL}energy/usage/type/usage`, // 获取区域下各基地特定能源使用信息
        boardFgpQuantity: `${API_URL}board/fgpQuantity/get`, // 获取区域下各基地特定能源使用信息
        boardFgpBaseQuantity: `${API_URL}board/fgpBaseQuantity/get`, // 各基地峰谷平分布
		
		// 区域平台标准管理相关接口
		energyStandardUpdate: API_URL + 'energyStandard/update',  // 更新能源标准配置'
		energyStandardList: API_URL + 'energyStandard/list',  // 获取能源标准配置列表'
		energyStandardConfigList: API_URL + 'standard/config/list',  // 获取能源标准已配置列表'
		energyStandardDelete: API_URL + 'energyStandard/delete',  // 删除能源标准配置'
		energyStandardAdd: API_URL + 'energyStandard/add',  // 增加能源标准配置'
		standardList: API_URL + 'standard/list',  // 指标标准数据列表'

		// 区域销发看板相关接口
		getInfoEnterprise: API_URL + 'market/enterprise/getInfo',  // 获取区域下所有企业
		getEnterpriseOutput: API_URL + 'market/enterprise/output/get',  // 获取企业下区域生产情况
		getOutput: API_URL + 'market/output/get',  // 获取区域生产情况
		getSell: API_URL + 'market/sell/get',  // 获取区域销发情况
		getEnterpriseSell: API_URL + 'market/enterprise/sell/get',  // 获取销发具体基地生产情况
		getAnaylyse: API_URL + 'market/anaylyse/get', // 获取区域销发分析
		getEnterpriseAnaylyse: API_URL + 'market/enterprise/anaylyse/get', // 获取区域具体基地销发分析
		getFinish: API_URL + 'market/finish/get', // 获取基地生产销发完成情况'

		// 环保数据看板相关接口
		getEnvironmentData: API_URL + '/board/environmentData/get', // 获取环保数据看板数据
    },
    enterprise: {  // 企业模型域
		// getGroupHomePage: ENT_URL+ 'enterprise/getGroupHomePage',
        // getGroupEquRate: ENT_URL + 'enterprise/getGroupEquRate',
		// recursionAddAreaShift: ENT_URL + 'productConfig/recursionAddAreaShift',
		// deleteShiftInfo: ENT_URL + 'productConfig/deleteShiftInfo',
		// addShiftInfo: ENT_URL + 'productConfig/addShiftInfo',
		// updateShiftInfo: ENT_URL + 'productConfig/updateShiftInfo',
		// getShiftListByEid: ENT_URL + 'productConfig/getShiftListByEid',
		// listAreaTreeToShfit: ENT_URL + 'productConfig/listAreaTreeToShfit',
		// listCourseTree: ENT_URL + 'course/listCourseTree',
		// deleteCourseGroup: ENT_URL + 'course/deleteCourseGroup',
		// addOrUpdateCourseGroup: ENT_URL + 'course/addOrUpdateCourseGroup',

	},
	oauth: { // 统一账号域
		//listUserMenus: OAUTH + 'resource/listUserMenus',
		listUserMenus: PR_URL + 'resources/userId/tree'
	},
	equipment: {
		getMonitorValue: API_URL + 'monitor/value/monitor/get', // 获取监测值
		//getMonitorValue: EQU_URL + 'monitor/getMonitorValue', // 获取监测值
		getMeterEquipmentParameterDataHistory: API_URL + 'meterEquipment/history/meter/parameter/equipment/get'
		//getMeterEquipmentParameterDataHistory: EQU_URL + 'meterEquipment/getMeterEquipmentParameterDataHistory',  // 获取企业表计和设备某参数的值的历史记录
	},
	product: {
		productIndexGet: API_URL + `product/index/get`,// 获取生产管控指标数据
	},
	// /standard/product/get
	standard: {
		productIndex: API_URL + `standard/product/get`,// 获取生产管控指标数据
	},
	orglist: {
		orglist: PR_URL + `orgUser/org/list`,	//查询用户的所在企业信息,用于用户登录后选企业
	}
};
