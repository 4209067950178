
import * as React from 'react';
import { getInitDate } from 'src/assets/js/com';

let timer: any = null;

const Timeing = () => {
   const [dateTime, setDateTime]: Array<Record<string, string> | any> = React.useState({});

   React.useEffect(() => {
      gettime({ setState: setDateTime });
   }, [])

   return (
      <div className="timebox">
         <p>{dateTime.hours}:{dateTime.minutes}:{dateTime.seconds}</p>
         <p>
            {dateTime.fullyear}年
            {dateTime.month}月
            {dateTime.nowday}日
         </p>
      </div>
   )
}

export default React.memo(Timeing, ()=>true);

// 获取时间方法
const gettime = ({ setState }: any) => {
   const date = new Date();
   const dateTime = {
      fullyear: date.getFullYear().toString(),
      month: getInitDate(date.getMonth() + 1),
      nowday: getInitDate(date.getDate()),
      hours: getInitDate(date.getHours()),
      minutes: getInitDate(date.getMinutes()),
      seconds: getInitDate(date.getSeconds())
   };
   setState(dateTime);

   clearTimeout(timer);
   timer = setTimeout(() => {
      gettime({ setState });
   }, 1000);
}