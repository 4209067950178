
import { StorageJson } from './common'

export function set(key:string,value:any){
	const res = StorageJson.indexOf(key);
	if(res !==-1){
		localStorage.setItem(key,JSON.stringify(value));
	}
	else{
		localStorage.setItem(key,value);
	}
}

export const get = async (key:string) =>{
	const res = StorageJson.indexOf(key);
	if(typeof key !== 'string') { return }
	if(res !==-1){
		const obj:any = localStorage.getItem(key)||{}
		return JSON.parse(obj);
	}
	else{
		return localStorage.getItem(key);
	}
}

export function del(key:any){
    localStorage.removeItem(key);
}

export function clear(){
    localStorage.clear();
}

