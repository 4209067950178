import * as React from 'react'
import Loadable from 'react-loadable'
import RenderRoutesMap from './RenderRoutesMap'

import * as storage from '../assets/js/localStorage'
import * as common from '../assets/js/common'
import { checkArrNotEmpty, clearStorageExcept } from '../assets/js/misc'
import { message } from 'antd';

class RouterGuard extends React.Component<any> {
    public constructor(props: any) {
        super(props);
    }

    public async componentWillMount() {  
        const checkMsg:any[] = [];
        let OrgTag: string = '';
        // 路由守卫配置
        checkMsg[0] = await storage.get(common.AccessTokenTag);
        checkMsg[1] = await storage.get(common.OrgIdTag);
        OrgTag = await storage.get(common.OrgTag);
        // console.log(this.props);
        this.routerMessage(checkMsg, OrgTag);
    }

    public routerMessage(checkMsg:string[],localOrg:string){
        const { location:{ pathname }, history:{ push }, path} = this.props;
        const pathOrg = pathname.substr(1)
        if(path==='/*'){
            // 登录处理
            this.loginMessage(pathOrg,checkMsg,localOrg);
        }else if(path!=='/'&&path!=='/Login'&&!checkArrNotEmpty(checkMsg)){
            // 登录路径为菜单路由且'不为登录项目跳转登录路由login'，但是无token
            // 存在企业编码则重定向到登录项目
            // 若无企业编号则跳转到 '/' 
            if(localOrg){
                message.warning('登录信息缺失(token)，请重新登录');
                push('/Login')
            }else{
                push('/')
            }
        }
    }

    public async loginMessage(pathOrg:string,checkMsg:string[],localOrg:string){
        const { history:{ push } } = this.props;
        // 登录路径为非菜单路由，统一默认为项目代号，并存storage
        // 实现同个项目的自动登录功能
        // console.log('localOrg: ', localOrg, pathOrg, checkMsg)
        if((localOrg !== null)&&(localOrg === pathOrg)&&checkArrNotEmpty(checkMsg)){
            push('/main')
        }else{
            // 新项目登录
            clearStorageExcept()
            await storage.set(common.OrgTag, pathOrg);
            push('/Login')
            // switchToPoiUnion(pathOrg)
        }
    }

    public render() {
        const { component, routes = [] } = this.props
        const LoadableComponent = Loadable({
            loader: () => import(`../${component}`),
            loading: () => (
                <span />
            )
        })
        return (
            <div>
                <LoadableComponent { ...this.props } />
                {RenderRoutesMap(routes)}
            </div>

        )
    }

}

export default RouterGuard;