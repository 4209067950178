/**
 * http axios 配置
 */

import axios from 'axios';
import * as Qs from 'qs';
import { message } from 'antd'

import * as storage from './localStorage';
// import * as elLoading from './loading';
import * as common from './common';
import { logout } from './misc';

// axios 配置
axios.defaults.timeout = 60 * 5000;
axios.defaults.paramsSerializer = (params: any) => {
    return Qs.stringify(params, { arrayFormat: 'indices' });
};

const NULL = 'null';

// http request 拦截器，请求时拦截
axios.interceptors.request.use(
    async (config: any) => {
        config.headers.poit_cloud_src_client = config.headers.poit_cloud_src_client || common.ClientType;
        config.headers.poit_cloud_org = await storage.get(common.OrgIdTag);
        config.headers.Authorization = await storage.get(common.AccessTokenTag);
        return config;
    },
    (err: any) => {
        return Promise.reject(err);
    },
);

// http response 拦截器
axios.interceptors.response.use(
    (response: any) => {
        // console.log('http response 拦截器：', response, common)
        if (response.data.retCode === common.TokenInvalidCode) {
            message.warning(`请求失败-${response.data.retMsg}`);
            // (response.body.retCode ==='-99') http请求发生系统错误不做登录界面退出处理
            // token失效或无效，返回登录界面
            logout();
        } else {
            return response;
        }
    },
    (error: any) => {
        if (error instanceof Error) {
            switch (error.message) {
                case 'Network Error':
                    error.message = '网络错误，请稍后再试';
                    break;
                case 'timeout of 60000ms exceeded':
                    error.message = '请求超时，请重新请求';
                    break;
            }
            message.error(error.message);
            return Promise.reject(error.message);
        }
        if (error.response) {
            switch (error.response.status) {
                case 401:
            }
        }
        return Promise.reject(error.response);
});

// 返回data信息
function checkStatus(response: any) {
    // loading
    // 如果http状态码正常，则直接返回数据
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
        return response.data;
        // 如果不需要除了data之外的数据，可以直接 return response.data
    }
    // 异常状态下，把错误信息返回去
    return {
        retCode: '-404',
        retMsg: '网络异常',
    };
}

// 返回params信息
function checkParams(response: any) {
    // loading
    // 如果http状态码正常，则直接返回数据
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
        return response.config.params;
    }
    // 异常状态下，把错误信息返回去
    return {
        retCode: '-404',
        retMsg: '网络异常',
    };
}

function checkCode(res: any) {
    // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
    if(!res){
        return;
    }
    if (res.status === -404) {
        console.log(res.msg);
    }
    if (res.data && (res.data.retCode !== '0')) {
        console.log(res.data.retMsg);
    }
    return res;
}


// 检查返回API是否为当前页面API
function checkResponse(response: any, callback: any) {
    checkCode(response);
    try {
        return callback(checkStatus(response), checkParams(response));
    } catch (err) {
        console.log('Callback Error:' + err);
    }
}

// getInit
export function getInit(mixOptions: any, callback: any) {
    // 参数转换
    const { url, params } = mixOptions;

    return axios({
        method: 'get',
        url,
        params, // get 请求时带的参数
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'poit_cloud_src_client': 'region'
        },
    }).then(
        (response) => {
            return checkResponse(response, callback);
    } );
}

// get ( client: 客户端（cloud-连接应用平台、service-数据服务平台、region-区域平台）)
export const get = async (mixOptions: any, callback: any) => {
    // 参数转换
    const { url, params={}, client = 'region'} = mixOptions;
    params[client==='region'?'regionId':'eid'] = await storage.get(common.OrgIdTag);

    return axios({
        method: 'get',
        url,
        params, // get 请求时带的参数
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'poit_cloud_src_client': client
        },
    }).then(
        (response: any) => {
            return checkResponse(response, callback);
        },
    );
}

// postInit
export const postInit = async (mixOptions: any, callback: any, id: string) => {
    // 参数转换
    const { url, body } = mixOptions;
    body.eid = await storage.get('orgId');
    // console.log('axios.postInit');

    // 打开加载提示框
    // let obj: any = null;
    if (id !== NULL) {
        // obj = elLoading.openLoading(id);
    }

    return axios({
        method: 'post',
        url,
        data: body,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    }).then(
        (response: any) => {
            if (id !== NULL) {
                // elLoading.closeLoading(obj);
            }
            return checkResponse(response, callback);
        },
    );
}


// post方法:组件所有传递参数统一通过body传递 请求头为url格式
export const post2 = async (mixOptions: any, callback: any) => {
    const { url, body, client = 'region' } = mixOptions;

    body[client==='region'?'regionId':'eid'] = await storage.get(common.OrgIdTag);

    return axios({
        method: 'post',
        url,
        data: body,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'poit_cloud_src_client': client
        },
    }).then(
        (response) => {
            return checkResponse(response, callback);
    } );
}


// post方法:组件所有传递参数统一通过body传递 请求头为json格式
export function postHeadJson(mixOptions: any, callback: any, id: string) {
    const { url, body } = mixOptions;

    // 打开加载提示框
    // let obj: any = null;
    if (id !== NULL) {
        // obj = elLoading.openLoading(id);
    }

    return axios({
        method: 'post',
        url,
        data: body,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json; charset=UTF-8',
        },
    }).then(
        (response) => {
            if (id !== NULL) {
                // elLoading.closeLoading(obj);
            }
            return checkResponse(response, callback);
    } );
}

export const post = async (mixOptions: any, callback: any) => {
    // 参数转换
    const { url, body, client = 'region' } = mixOptions;

    body[client==='region'?'regionId':'eid'] = await storage.get(common.OrgIdTag);

    return axios({
        method: 'post',
        url,
        data: Qs.stringify(body),
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'poit_cloud_src_client': client
        },
    }).then(
        (response) => {
            return checkResponse(response, callback);
        },
    );
}


// post传递json方法:组件所有传递参数统一通过formData传递
export const postJson = async (mixOptions: any, callback: any) => {
    // 参数转换
    const { url, json, client = 'region' } = mixOptions;

    json[client==='region'?'regionId':'eid'] = await storage.get(common.OrgIdTag);

    return axios({
        method: 'post',
        url,
        data: json,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'poit_cloud_src_client': client
        },
    }).then(
        (response) => {
            return checkResponse(response, callback);
        },
    );
}


// post传递json方法:组件所有传递参数统一通过formData传递
export function postJson2(mixOptions: any, callback: any, id: string) {
    // 参数转换
    const { url, json } = mixOptions;
    // 打开加载提示框
    // let obj: any = null;
    if (id !== NULL) {
        // obj = elLoading.openLoading(id);
    }

    return axios({
        method: 'post',
        url,
        data: json,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Accept': '*/*',
            'Accept-Encoding': 'gzip,deflate',
            'Content-Type': 'application/json',
        },
    }).then(
        (response) => {
            if (id !== NULL) {
                // elLoading.closeLoading(obj);
            }
            return checkResponse(response, callback);
        },
    );
}

// post传递formData方法:组件所有传递参数统一通过formData传递
export function postForm(mixOptions: any, callback: any, id: string) {
    // 参数转换
    const { url, forms } = mixOptions;

    // 打开加载提示框
    // let obj: any = null;
    if (id !== NULL) {
        // obj = elLoading.openLoading(id);
    }

    return axios({
        method: 'post',
        url,
        data: forms,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'multipart/form-data',
        },
    }).then(
        (response) => {
            if (id !== NULL) {
                // elLoading.closeLoading(obj);
            }
            return checkResponse(response, callback);
        },
    );
}


// post传递formData方法:组件所有传递参数统一通过formData传递
export function postForm2(mixOptions: any, callback: any, id: string) {
    // 参数转换
    const url = mixOptions.url;
    const body = mixOptions.forms;

    // 打开加载提示框
    // let obj: any = null;
    if (id !== NULL) {
        // obj = elLoading.openLoading(id);
    }

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios.post(url, body, config).then(
        (response) => {
            if (id !== NULL) {
                // elLoading.closeLoading(obj);
            }
            return checkResponse(response, callback);
        },
    );
}
