import * as React from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom"
import RenderRoutesMap from './RenderRoutesMap'

const RenderRoutes: ({ routes, extraProps, switchProps }: {
	routes: any;
	extraProps?: {};
	switchProps?: {};
}) => any =  ({ routes, extraProps, switchProps }) =>{
	return (
		<Router>
			<Switch {...switchProps}>
				{RenderRoutesMap(routes)}
			</Switch>
		</Router>
	)
}

export default RenderRoutes
