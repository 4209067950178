
// const URL_TEST = 'http://test.poi-t.cn:8310/';   // 测试环境
const URL_TEST = 'http://192.168.100.31/';// 后端测试环境
const pre_release = 'http://192.168.100.44:8300/'// 后端预发布环境
// const URLstr = 'http://192.168.100.92:8310/';  // 本地服务测试
// const  URLstr = 'https://test.poi-t.cn:18310/'  // 新-测试环境
// const  URLstr = 'https://api.poi-t.cn/'  

// 判断是否为正式环境，切换接口调用
const  headURL = /poi-t/.test(window.location.host);
const  headURL2 = /localhost:9004/.test(window.location.host);
const headURL3 = /192.168.100.37/.test(window.location.host);

const  URLstr = (headURL || headURL2) ? 'https://api.poi-t.cn/' : (headURL3 ? pre_release : URL_TEST);

export const loginurl = (headURL || headURL2) ? 'https://poi-union.poi-t.cn/' : (headURL3 ? 'http://192.168.100.37:8070' : 'http://192.168.100.32:8070') ;
export const EFF_URL = URLstr + 'cloud-biz-efficiency/';
export const EQU_URL = URLstr + 'cloud-biz-equipment/';
export const ENT_URL = URLstr + 'cloud-biz-enterprise/';
export const PRO_URL = URLstr + 'cloud-biz-product/';
export const RPT_URL = URLstr + 'cloud-biz-report/';
export const STA_URL = 'cloud-statistics/';
export const OAUTH = URLstr + 'cloud-oauth/';
export const EMS_URL = URLstr + 'cloud-api-ems/';
export const API_URL = URLstr + 'cloud-region-api/';
export const PR_URL = URLstr + 'poit-region/';